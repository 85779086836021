.content-section-wrapper {
    .content-section {

        p {
			+ h1,
			+ h2,
			+ .wp-block h1,
			+ .wp-block h2 {
				@apply mt-12;
			}
            + h3,
			+ h4,
			+ h5,
			+ h6,
			+ .wp-block h3,
            + .wp-block h4,
            + .wp-block h5,
            + .wp-block h6 {
				@apply mt-6 my-4;
			}
		}

        h1,
        .h1 {
            ~ h2,
            ~ .h2  {
                @apply text-5xl;
            }
        }

        h2,
        .h2 {
            + h3,
			+ h4,
			+ h5,
			+ h6,
			+ .wp-block h3,
            + .wp-block h4,
            + .wp-block h5,
            + .wp-block h6 {
				@apply my-4;
			}
            @apply text-5xl;
        }

        * {
            &:last-child {
                @apply mb-0;
            }
        }

        ul,
		ol {
            + h1,
			+ h2,
            + h3,
			+ h4,
			+ h5,
			+ h6,
			+ .wp-block h1,
			+ .wp-block h2,
			+ .wp-block h3,
            + .wp-block h4,
            + .wp-block h5,
            + .wp-block h6 {
				@apply mt-12;
			}
            li:has(img) {
                @apply list-none;
            }
            
        }

    }

    .btn-container {
        @apply mt-4;
    }
}

// For Plant Tyres page
body.plant-tyres,
body.page-id-156 {

    .content-section-wrapper {
        .image {
            img {
                @apply object-right;
            }
        }
    }
}

.areas-we-cover-text {
    ul {
        margin-bottom: 1.5rem;
        row-gap: 1rem;
        h5 {
            margin-bottom: 0;
            width: 100%;
        }
        
        list-style: none;
    }
}


// branches single layout

body.single-branches #mainContent {
    max-width: 100% !important;
}

body.single-branches #mainContent .lg\:right-4 {
    right: 0 !important;
}
body.single-branches #mainContent iframe {
    width: 100%;
}