.content-section-wrapper .content-section p + h1,
.content-section-wrapper .content-section p + h2,
.content-section-wrapper .content-section p + .wp-block h1,
.content-section-wrapper .content-section p + .wp-block h2 {
  @apply mt-12;
}
.content-section-wrapper .content-section p + h3,
.content-section-wrapper .content-section p + h4,
.content-section-wrapper .content-section p + h5,
.content-section-wrapper .content-section p + h6,
.content-section-wrapper .content-section p + .wp-block h3,
.content-section-wrapper .content-section p + .wp-block h4,
.content-section-wrapper .content-section p + .wp-block h5,
.content-section-wrapper .content-section p + .wp-block h6 {
  @apply mt-6 my-4;
}
.content-section-wrapper .content-section h1 ~ h2,
.content-section-wrapper .content-section h1 ~ .h2,
.content-section-wrapper .content-section .h1 ~ h2,
.content-section-wrapper .content-section .h1 ~ .h2 {
  @apply text-5xl;
}
.content-section-wrapper .content-section h2,
.content-section-wrapper .content-section .h2 {
  @apply text-5xl;
}
.content-section-wrapper .content-section h2 + h3,
.content-section-wrapper .content-section h2 + h4,
.content-section-wrapper .content-section h2 + h5,
.content-section-wrapper .content-section h2 + h6,
.content-section-wrapper .content-section h2 + .wp-block h3,
.content-section-wrapper .content-section h2 + .wp-block h4,
.content-section-wrapper .content-section h2 + .wp-block h5,
.content-section-wrapper .content-section h2 + .wp-block h6,
.content-section-wrapper .content-section .h2 + h3,
.content-section-wrapper .content-section .h2 + h4,
.content-section-wrapper .content-section .h2 + h5,
.content-section-wrapper .content-section .h2 + h6,
.content-section-wrapper .content-section .h2 + .wp-block h3,
.content-section-wrapper .content-section .h2 + .wp-block h4,
.content-section-wrapper .content-section .h2 + .wp-block h5,
.content-section-wrapper .content-section .h2 + .wp-block h6 {
  @apply my-4;
}
.content-section-wrapper .content-section *:last-child {
  @apply mb-0;
}
.content-section-wrapper .content-section ul + h1,
.content-section-wrapper .content-section ul + h2,
.content-section-wrapper .content-section ul + h3,
.content-section-wrapper .content-section ul + h4,
.content-section-wrapper .content-section ul + h5,
.content-section-wrapper .content-section ul + h6,
.content-section-wrapper .content-section ul + .wp-block h1,
.content-section-wrapper .content-section ul + .wp-block h2,
.content-section-wrapper .content-section ul + .wp-block h3,
.content-section-wrapper .content-section ul + .wp-block h4,
.content-section-wrapper .content-section ul + .wp-block h5,
.content-section-wrapper .content-section ul + .wp-block h6,
.content-section-wrapper .content-section ol + h1,
.content-section-wrapper .content-section ol + h2,
.content-section-wrapper .content-section ol + h3,
.content-section-wrapper .content-section ol + h4,
.content-section-wrapper .content-section ol + h5,
.content-section-wrapper .content-section ol + h6,
.content-section-wrapper .content-section ol + .wp-block h1,
.content-section-wrapper .content-section ol + .wp-block h2,
.content-section-wrapper .content-section ol + .wp-block h3,
.content-section-wrapper .content-section ol + .wp-block h4,
.content-section-wrapper .content-section ol + .wp-block h5,
.content-section-wrapper .content-section ol + .wp-block h6 {
  @apply mt-12;
}
.content-section-wrapper .content-section ul li:has(img),
.content-section-wrapper .content-section ol li:has(img) {
  @apply list-none;
}
.content-section-wrapper .btn-container {
  @apply mt-4;
}

body.plant-tyres .content-section-wrapper .image img,
body.page-id-156 .content-section-wrapper .image img {
  @apply object-right;
}

.areas-we-cover-text ul {
  margin-bottom: 1.5rem;
  row-gap: 1rem;
  list-style: none;
}
.areas-we-cover-text ul h5 {
  margin-bottom: 0;
  width: 100%;
}

body.single-branches #mainContent {
  max-width: 100% !important;
}

body.single-branches #mainContent .lg\:right-4 {
  right: 0 !important;
}

body.single-branches #mainContent iframe {
  width: 100%;
}